<div class="grid grid-cols-12 gap-4">
  <div class="col-span-6">
    <p class="text-vikingo-h2-secondary-bold">{{ actionTable.title }}</p>
    <p class="my-5 text-vikingo-h4-neutral90-bold">{{ actionTable.subTitle }}</p>
  </div>
  <div class="col-span-6 flex items-end justify-end">
    <button class="w-[200px] btn-vikingo-primary" (click)="actionTable.buttons[0].function()">
      {{ actionTable.buttons[0].btnTitle }}
    </button>
  </div>
</div>

<div class="my-20">
  <div class="grid grid-cols-12 gap-4">
    <div class="col-start-9 col-span-4">
      <input id="inputField" type="text" placeholder="Buscar" disabled
        class="px-4 py-2 border border-text70 rounded-lg hover:border-primaryDark hover:text-primaryDark text-primaryDark placeholder-text70 w-full" />
    </div>

    <div class="py-5 col-span-12">
      <ngx-datatable #dataTable class="tailwind-table striped hover" [columnMode]="ColumnMode.force" [limit]="5"
        [rows]="dataList" [headerHeight]="60" [footerHeight]="100" [rowHeight]="60"
        [messages]="{emptyMessage: 'No hay areas disponibles'}" [count]="50" [offset]="0">

        <ngx-datatable-column name="Código barra / SKU" prop="sku"></ngx-datatable-column>
        <ngx-datatable-column name="Descripción" prop="descripcion"></ngx-datatable-column>
        <ngx-datatable-column name="Lote" prop="lote"></ngx-datatable-column>
        <ngx-datatable-column name="Fecha vencimiento" prop="fechaVencimiento"></ngx-datatable-column>
        <ngx-datatable-column name="Licencia" prop="licencia"></ngx-datatable-column>
        <ngx-datatable-column name="Cantidad cajas" prop="cantidad"></ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">

            <ng-container *ngIf="dataTable != null">
              <app-pagination [currentPage]="curPage" [collectionSize]="rowCount" [pageSize]="pageSize"
                [tableComponent]="dataTable" style="width: 100%;">
              </app-pagination>
            </ng-container>

          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>

  </div>
</div>